import { A, D, F, G, O, S, pipe } from "@mobily/ts-belt";
import Cookies, { type CookieAttributes } from "js-cookie";
import type { GetServerSidePropsContext } from "next";
import { match } from "ts-pattern";

export const getCookieValue =
  (cookieName: string) =>
    (cookieString: string | string[]): O.Option<string> => {
      return pipe(
        match(cookieString)
          .when(G.isString, (cookieString: string) =>
            pipe(cookieString, S.split(" "))
          )
          .otherwise(F.identity),
        A.map(S.split("=")),
        A.find(([key]) => key === cookieName),
        O.flatMap(A.at(1)),
        O.map(S.replace(";", "")),
        O.flatMap(O.fromPredicate((x) => x !== "undefined"))
      );
    };

export const getCookieFromCtx = (
  ctx: GetServerSidePropsContext,
  cookieName: string
): O.Option<string> => {
  return pipe(
    ctx.req.headers,
    O.fromNullable,
    O.flatMap(D.get("cookie")),
    O.flatMap(getCookieValue(cookieName))
  );
};

export const setCookieIfUnset = (
  key: string,
  value: string,
  options?: CookieAttributes
) => {
  Cookies.get(key) || Cookies.set(key, value, options);
};
