import { O, flow, pipe } from "@mobily/ts-belt";
import type { UtmData } from "../../middleware";
import Cookies from "js-cookie";

import { Env } from "../Env";
import { createContext, useContext } from "react";
import type { ApplicationFeatures } from "./GrowthBookCTX";

const parseJSON =
  <T>(value: string) =>
    () =>
    JSON.parse(value) as T;

export type TrackerContext = {
  trackingGrowthBookClick: (event: Gtag.CustomParams) => void;
  trackingGrowthBookScroll: (event: Gtag.CustomParams) => void;
};
export const make: (
  anonymous_id: string,
  featureFlags: O.Option<ApplicationFeatures>
) => TrackerContext = (anonymous_id) => {
  const utmInfo = pipe(
    Cookies.get(`${Env.COOKIE_PREFIX}utm`),
    O.fromNullable,
    O.flatMap(flow(parseJSON<UtmData>, O.fromExecution)),
    O.getWithDefault({})
  );

  const trackingGrowthBookClick = (eventParams: Gtag.CustomParams) => {
    gtag("event", "custom_click", {
      anonymous_id,
      ...eventParams,
      ...utmInfo,
    });
  };

  const trackingGrowthBookScroll = (eventParams: Gtag.CustomParams) => {
    gtag("event", "custom_scroll", {
      anonymous_id,
      ...eventParams,
      ...utmInfo,
    });
  };
  return { trackingGrowthBookClick, trackingGrowthBookScroll };
};

export const trackerContext = createContext<O.Option<TrackerContext>>(O.None);

export const useTracker = () => {
  const trackerContext_ = useContext(trackerContext);
  return {
    trackingClick: (event: Gtag.CustomParams) =>
      O.tap(trackerContext_, (tracker) =>
        tracker.trackingGrowthBookClick(event)
      ),
    trackingScroll: (event: Gtag.CustomParams) =>
      O.tap(trackerContext_, (tracker) =>
        tracker.trackingGrowthBookScroll(event)
      ),
  };
};
