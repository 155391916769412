import type { AppProps } from "next/app";

import ogImage from "/public/assets/pc/images/og.png";
import favicon192 from "/public/assets/pc/images/android-icon-192x192.png";
import favicon16 from "/public/assets/pc/images/favicon-16x16.png";
import favicon32 from "/public/assets/pc/images/favicon-32x32.png";
import favicon96 from "/public/assets/pc/images/favicon-96x96.png";
import Head from "next/head";

import * as 회사정보 from "../components/데이터/회사정보";
import { 검색키워드 } from "../components/데이터/검색키워드";

import { useEffect, useMemo } from "react";

import { datadogRum } from "@datadog/browser-rum-slim";
import Script from "next/script";
import { Env } from "../components/Env";

import * as GrowthbookCTX from "../components/util/GrowthBookCTX";
import Cookies from "js-cookie";
import { GrowthBook연결 } from "../components/데이터/GrowthBook연결";
import { O, flow, pipe } from "@mobily/ts-belt";
import type { UtmData } from "../middleware";

import * as Tracker from "../components/util/Tracker";
import dayjs from "dayjs";
import { GrowthBook } from "@growthbook/growthbook";
import type { GetServerSidePropsContext } from "next";
import {
  getCookieFromCtx,
  setCookieIfUnset,
} from "../components/api/parseCookie";
import { v4 as uuidv4 } from "uuid";
import chalk from "chalk";

export type ApplicationPageProps = {
  featureFlags: O.Option<GrowthbookCTX.ApplicationFeatures>;
  growthbookConnectionId: O.Option<string>;
  url: string;
};

export const loadApplicationPageProps = async (
  ctx: GetServerSidePropsContext
): Promise<ApplicationPageProps> => {
  const growthbookConnectionId = pipe(
    getCookieFromCtx(ctx, GrowthBook연결.COOKIE),
    O.getWithDefault(uuidv4())
  );

  const gb = new GrowthBook({
    apiHost: Env.GROWTHBOOK_API_HOST,
    clientKey: Env.GROWTHBOOK_CLIENT_KEY,
    // Enable easier debugging during development
    enableDevMode: true,
    // Targeting attributes
    attributes: {
      id: growthbookConnectionId,
    },

    trackingCallback: (experimentId, result) => {
      console.log(
        `Experiment ${JSON.stringify(
          experimentId
        )} was shown to this user with result ${JSON.stringify(result)}`
      );
    },
  });

  // Wait for features to be available
  await gb.loadFeatures({ autoRefresh: true, timeout: 10000 });

  return {
    featureFlags: GrowthbookCTX.make(gb),
    growthbookConnectionId,
    url: ctx.resolvedUrl,
  };
};

export default function App({
  Component,
  pageProps,
}: AppProps<ApplicationPageProps>) {
  const 회사정보_값 = 회사정보.값;
  const featureFlag: O.Option<GrowthbookCTX.ApplicationFeatures> =
    pageProps.featureFlags;
  const growthbookConnectionId = pageProps.growthbookConnectionId;

  pipe(
    growthbookConnectionId,
    O.tap((growthbookConnectionId) => {
      setCookieIfUnset(GrowthBook연결.COOKIE, growthbookConnectionId, {
        expires: dayjs(dayjs().add(4, "weeks").toISOString()).toDate(),
      });
    })
  );

  const parseJSON =
    <T,>(value: string) =>
      () =>
      JSON.parse(value) as T;

  const utmCookie = pipe(
    Cookies.get(`${Env.COOKIE_PREFIX}utm`),
    O.fromNullable,
    O.flatMap(flow(parseJSON<UtmData>, O.fromExecution)),
    O.getWithDefault({})
  );

  const experimentInfo = useMemo(
    () => ({
      gb_connection_id: growthbookConnectionId,
      ...GrowthbookCTX.allFeatures(featureFlag),
    }),
    [featureFlag, growthbookConnectionId]
  );

  Cookies.set(
    `${Env.COOKIE_PREFIX}signup`,
    JSON.stringify({
      ...experimentInfo,
      ...utmCookie,
    }),
    {
      domain: Env.COOKIE_DOMAIN,
    }
  );

  useEffect(() => {
    if (Env.DATADOG_ENABLED) {
      datadogRum.init({
        applicationId: "8909e802-0200-4b91-80c4-3326fa591b50",
        clientToken: "pubd66cb01ed3921d8119b5997dbef8eb26",
        site: "datadoghq.com",
        service: "marketbom-gate",
        env: Env.APP_ENV,
        sessionSampleRate: 10,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      });
      datadogRum.startSessionReplayRecording();
    }
  }, []);

  useEffect(() => {
    pipe(
      growthbookConnectionId,
      O.tap(() => {
        gtag("event", "custom_visit", {
          ...experimentInfo,
          ...utmCookie,
          url: pageProps.url,
        });
      })
    );
  }, [experimentInfo, growthbookConnectionId, pageProps.url, utmCookie]);

  pipe(
    featureFlag,
    O.match(
      (featureFlag) => {
        console.log(
          `~> Marketboro Gate\nversion: ${Env.APP_VERSION}`,
          `\n\n::current test::\n${GrowthbookCTX.summary(featureFlag)}`
        );
      },
      () => {
        console.log(
          `~> Marketboro Gate\nversion: ${Env.APP_VERSION}`,
          `\n\n::current test::\n${chalk.red("no test")}`
        );
      }
    )
  );

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="google-site-verification"
          content="2Bd-bOMs42cBGaiTZg6Im7fnEjSJBUtLldlpZgQ8-G8"
        />
        <meta
          name="naver-site-verification"
          content="d7d844274e0835714439708eb5bad1d315e090d8"
        />
        <meta
          name="facebook-domain-verification"
          content="1zk0lnor0dxkt5gchfb6oxhtqi3rjt"
        />

        <meta property="og:title" content="식자재 수발주 마켓봄" />
        <meta property="og:site_name" content="식자재 수발주 마켓봄" />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="수발주 프로그램, 식자재 ERP, 식자재 프로그램, 식자재 전산, 식자재 유통 전산"
        />
        <meta property="og:image" content={ogImage.src} />
        <meta property="og:url" content="http://marketbom.com" />
        <meta
          name="description"
          content="수발주 프로그램, 식자재 ERP, 식자재 프로그램, 식자재 전산, 식자재 유통 전산"
        />
        <meta
          name="keyword"
          content={검색키워드}
        />
        <meta
          name="format-detection"
          content="telephone=no, date=no, email=no, address=no"
        />
        <link rel="canonical" href="http://marketbom.com" />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href={favicon192.src}
        />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32.src} />
        <link rel="icon" type="image/png" sizes="96x96" href={favicon96.src} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16.src} />

        <title>식자재 수발주 마켓봄</title>
      </Head>

      <Script
        id="channelTalk"
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: `
          (function () {
  const w = window;
  if (w.ChannelIO) {
    return (window.console.error || window.console.log || function () {})(
      "ChannelIO script included twice."
    );
  }
  const ch = function () {
    ch.c(arguments);
  };
  ch.q = [];
  ch.c = function (args) {
    ch.q.push(args);
  };
  w.ChannelIO = ch;
  function l() {
    if (w.ChannelIOInitialized) {
      return;
    }
    w.ChannelIOInitialized = true;
    const s = document.createElement("script");
    s.type = "text/javascript";
    s.async = true;
    s.src = "https://cdn.channel.io/plugin/ch-plugin-web.js";
    s.charset = "UTF-8";
    const x = document.getElementsByTagName("script")[0];
    x.parentNode.insertBefore(s, x);
  }
  if (document.readyState === "complete") {
    l();
  } else if (window.attachEvent) {
    window.attachEvent("onload", l);
  } else {
    window.addEventListener("DOMContentLoaded", l, false);
    window.addEventListener("load", l, false);
  }
})();
ChannelIO("boot", {
  pluginKey: "${Env.CHANNELIO_SDK_KEY}",
});
`,
        }}
      />

      <Tracker.trackerContext.Provider
        value={O.Some(Tracker.make(growthbookConnectionId ?? "", featureFlag))}
      >
        <GrowthbookCTX.GrowthBookCTX.Provider
          value={featureFlag}
          key={"GrowthBookCTX"}
        >
          <회사정보.회사정보Context.Provider value={회사정보_값}>
            <Component {...pageProps} />
          </회사정보.회사정보Context.Provider>
        </GrowthbookCTX.GrowthBookCTX.Provider>
      </Tracker.trackerContext.Provider>
    </>
  );
}
