import { createContext } from "react";

export const 값 = {
  회사명: "(주)마켓보로",
  대표이사: "임사성",
  주소: {
    건물주소: "경기도 성남시 분당구 판교로 323",
    상세주소: "8층(투썬벤처포럼빌딩)",
  },
  회사전화번호: "1522-9559",
  대표번호: "031-698-3417",
  문의가능시간: "평일 09시 - 18시 (점심시간 12시 30분 - 13시 30분)",
  문의가능시간_점심시간제외: "(평일 09시 - 18시)",
  이메일: "info@marketbom.com",
  개인정보담당자: "오진영 이사 (info@marketbom.com)",
  사업자등록번호: "479-86-00423",
  통신판매업신고번호: "제2018-성남분당-0313호",
};

export type t = typeof 값;
export const 회사정보Context = createContext<t>(값);
