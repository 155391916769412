import { createContext } from "react";
import type { AppFeatures } from "./app-features";
import type { GrowthBook } from "@growthbook/growthbook";
import { A, D, O, flow, pipe } from "@mobily/ts-belt";
import chalk from "chalk";

export const availableFeaturesWithDefaultValue = {
  "simplified-register": true,
  simplified_register: true,
  miniature: false,
} as const satisfies {
  [key in keyof AppFeatures]?: AppFeatures[key];
};
export type AvailableFeatures = keyof typeof availableFeaturesWithDefaultValue;
export type ApplicationFeatures = {
  [key in AvailableFeatures]: AppFeatures[key];
};

export const make = (
  growthBookInstance: GrowthBook<Record<string, unknown>>
): ApplicationFeatures => {
  const getX = <K extends AvailableFeatures>(x: K): AppFeatures[K] =>
    growthBookInstance.getFeatureValue(
      x,
      availableFeaturesWithDefaultValue[x]
    ) as unknown as AppFeatures[K];

  const simplifiedRegister = getX("simplified-register");
  const miniature = getX("miniature");

  return {
    "simplified-register": simplifiedRegister,
    simplified_register: simplifiedRegister,
    miniature,
  };
};

export const summary = (ctx: ApplicationFeatures): string => {
  const genSummary = flow(
    D.keys<ApplicationFeatures>,
    A.map((key) => [key, getValue(key)(ctx)]),
    A.map(([key, value]) => `- ${chalk.magenta(key)}: ${chalk.cyan(value)}`),
    A.join("\n")
  );

  return pipe(ctx, O.map(genSummary), O.getWithDefault(""));
};

export const getValue = <T extends AvailableFeatures>(key: T) => {
  return flow(
    O.map((ctx: ApplicationFeatures) => ctx[key]),
    O.getWithDefault<ApplicationFeatures[T]>(
      availableFeaturesWithDefaultValue[
        key
      ] as unknown as ApplicationFeatures[T]
    )
  );
};

export const allFeatures = (featureFlags: O.Option<ApplicationFeatures>) => ({
  ...availableFeaturesWithDefaultValue,
  ...featureFlags,
});

export const GrowthBookCTX = createContext<O.Option<ApplicationFeatures>>(null);
